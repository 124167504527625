.icon {
  font-size: 24px;
}
.wrapUser {
  p {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 0;
  }
  span {
    font-size: 10px;
  }
}

.settings {
  background: red;
  width: min-content;
  height: min-content;
  margin: 0;
  padding: 0;
}

.settingsLink {
  a {
    color: #2d3436;
    display: block;
  }
}
