.ck-editor__editable {
  min-height: 250px;
}
.editorDisabled {
  .ck-read-only {
    background: transparent !important;
  }
  .ck-sticky-panel {
    display: none;
  }
}

div.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 20px !important;
}
