.table {
  table tr td {
    white-space: nowrap;
    max-width: 380px;
    overflow: hidden;
    text-overflow: ellipsis;
    p {
      white-space: normal;
    }
  }
}
.finish {
  color: #28a745;
}
.arrow {
  font-size: 40px;
}
.icon {
  font-size: 20px;
  color: #0063f7;
  margin-right: 5px;
}
.iconDelete {
  color: #ff455e;
}

.client {
  font-size: 16px;

  span {
    font-weight: 500;
    font-size: 18px;
  }
}

.card {
  margin-bottom: 50px !important;
}

.wrap {
  display: flex;
  align-items: center;
  column-gap: 10px;

  h5 {
    margin: 0 5px 0 0;
  }
}
