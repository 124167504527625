.header {
  display: none !important;
  @media (max-width: 1199px) {
    display: block !important;
  }
}

.menuIcon {
  margin-right: auto;
}

.logoWrapper {
  margin-right: auto;
}
.content {
  margin-top: 60px !important;
}
