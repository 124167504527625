.stepButton {
  width: min-content !important;
  display: flex;
  margin-left: auto;
  white-space: nowrap !important;
  @media (max-width: 560px) {
    width: 100% !important;
  }
}

.wrapper {
  position: relative;

  a {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -15px;
  }

  .wrapSteps {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #2d3436;
    margin: 10px 0 20px;
    border-radius: 5px;

    span {
      display: flex;
    }
  }
}

.buttonWrapper {
  width: 100%;
  justify-content: flex-end;
  margin-top: 20px;
}
